/* make the customizations for bootstrap */
$primary: #4785EC;    /* blue */
$info: #EF3A58;        /* red */
/* Dark Mode */
$secondary: #6E6E6E;  /* grey */
$light: #383838;      /* dark grey */
$white: #202020;      /* black */
$theme-colors: (
  'primary': $primary,
  /* 'secondary': #EFEFEF, */  /* grey */
  'info': $info,
  /* Dark Mode */
  'secondary': $secondary,
  'light': $light,      /* dark grey */
  'white': $white,      /* black */
);

/* import bootstrap to set changes */
@import "../node_modules/bootstrap/scss/bootstrap";

$round-container-size: 96px;
$round-container-md-size: 48px;
$round-container-sm-size: 24px;
$round-button-size: 48px;
$navbar-height: 65px;
$navbar-height-bottom: 55px;
/* px-1 on mobile*/
$mobile-grid-gutter-width: $spacer * 0.25;

/* App */
html, body, #root, {
  height: 100%;
}

small {
  font-size: 0.8rem !important;
  font-weight: 400 !important;
}

.text-xs {
  font-size: 0.75rem !important;
  font-weight: 400 !important;
}
@include media-breakpoint-down(xs) {
  .text-xs {
    font-size: 0.6rem !important;
    font-weight: 400 !important;
  }
}

/*
 * App body (excludes Navbar) fills height.
 * css-tricks.com/boxes-fill-height-dont-squish
 */
#root {
  display: flex;
  flex-direction: column;
}

/* Shape */
.rounded-2 {
  border-radius: 0.5rem;
}

.rounded-3 {
  border-radius: 0.8rem !important;
}

.rounded-corner {
  border-radius: 9999px;
}

/* Color */
.transparent {
  opacity: 0;
}

.transparent-50 {
  opacity: 0.5;
}

.transparent-80 {
  opacity: 0.8;
}

.outline-none {
  outline: none;
}

.bg-primary-transparent-80 {
  background-color: rgba($primary, .8);
}

.bg-info-transparent-80 {
  background-color: rgba($info, .8);
}

.bg-dark-transparent-80 {
  background-color: rgba($dark, .8);
}

.bg-white-gradient {
  background-image: linear-gradient(rgba($white, .0), $white, $white);
}

.bg-grey {
  background-color: rgba($black, .1);
}

.bg-light-hover:hover {
  background-color: rgba($black, .1);
}

.bg-0 {
  /* Light blue */
  background-color: #80C8E3 !important;
}

.bg-1 {
  /* Beige */
  background-color: #D1D4C2 !important;
}

.bg-2 {
  /* Blue-grey */
  background-color: #76A4BE !important;
}

.bg-3 {
  /* Turquoise */
  background-color: #A5D6D6 !important;
}

.bg-4 {
  /* Red */
  background-color: #E87889 !important;
}

.bg-5 {
  /* Purple*/
  background-color: #94687E !important;
}

.bg-6 {
  /* Green */
  background-color: #91D4D9 !important;
}

.bg-7 {
  /* Pink */
  background-color: #F1CDD7 !important;
}

.bg-8 {
  /* Blue */
  background-color: #0B49AA !important;
}

.bg-9 {
  /* N/A */
  background-color: #A79EAF !important;
}

.backdrop-dark{
  opacity: 0.8 !important;
}

/* Position */
.center-absolute-self {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  margin: auto !important;
}

/* Container */
.app-body-container {
  @extend .h-body;
  /* these are the flex items */
  flex: 1;
}

.px-content-container {
  /*
   * px-1 on mobile.
   * getbootstrap.com/docs/4.0/utilities/spacing/
   */
  padding-left: $mobile-grid-gutter-width * 5;
  padding-right: $mobile-grid-gutter-width * 5;
}
@include media-breakpoint-up(sm) {
  .px-content-container {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
}

.page-container {
  @extend .h-100;
  @extend .px-content-container;
}

.round-container {
  width: $round-container-size;
  height: $round-container-size;
  border-radius: 9999px;
  position: relative;
}
.round-container * {
  @extend .center-absolute-self;
}

.round-container-md {
  @extend .round-container;
  width: $round-container-md-size;
  height: $round-container-md-size;
}

.round-container-sm {
  @extend .round-container;
  width: $round-container-sm-size;
  height: $round-container-sm-size;
}

/* Size */
.h-body {
  height: calc(100% - 65px); /* $navbar-height = 65px*/
}

.h-10 {
  height: 10% !important;
}

.h-100px {
  height: 100px;
}

.h-150px {
  height: 150px;
}

.flex-stretch {
  flex: 1 1;
}

.mw-sm {
  /*
   * Bootstrap sm
   * getbootstrap.com/docs/4.1/layout/overview/#responsive-breakpoints
   */
  max-width: 576px;
}

.mw-md {
  /*
   * Bootstrap md
   * getbootstrap.com/docs/4.1/layout/overview/#responsive-breakpoints
   */
  max-width: 768px;
}

/*
 * A proper height constraint to display media content (iframe, video, photo, etc.).
 */
.mh-media-container {
  /* max-height = window-height - navbar-height - page-footer-peeker-height. */
  max-height: calc(100vh - #{$navbar-height} - 150px);
}
/*
 * Max width for a fixed aspect ratio of 16 by 9 that ensures the
 * height is always in range of mh-media-container.
 *
 * 16 / 9 = 1.777778
 */
.mw-16by9-media-container {
  max-width: calc((100vh - #{$navbar-height} - 150px) * 1.777778);

}
@include media-breakpoint-up(lg) {
  /*
  .mh-media-container {
    min-height: 460px;
  }
  */
  .mw-16by9-media-container {
    min-width: calc(460px * 1.777778);
  }
}

.mh-7rem {
  max-height: 7rem;
}

.mh-50vh {
  max-height: 50vh;
}

.mh-80vh {
  max-height: 80vh;
}

.min-w-0 {
  min-width: 0%;
}

.min-w-50 {
  min-width: 50%;
}

.min-h-round-button-size {
  min-height: $round-button-size;
}

/* Spacing */
.pb-safe-area-inset {
  /* https://medium.com/butternut-engineering/sticky-bottom-navigation-7b6038bd27b6 */
  padding-bottom: env(safe-area-inset-bottom, 0)  !important;
}

.top-navbar-height {
  top: $navbar-height;
}

.top-n-navbar-height {
  top: -$navbar-height;
}

.top-navbar-height-bottom {
  top: $navbar-height-bottom;
}

.top-0 {
  top: 0px;
}

.top-2 {
  top: 0.5rem !important;
}

.bottom-0 {
  bottom: 0px;
}

.bottom-1 {
  bottom: $spacer * 0.25;
}

.bottom-30px {
  bottom: 30px;
}

.bottom-80px {
  bottom: 80px;
}

.bottom-120px {
  bottom: 120px;
}

.left-0 {
  left: 0px;
}

.left-1 {
  left: $spacer * 0.25;
}

.left-2 {
  left: 0.5rem !important;
}

.left-30px {
  left: 30px;
}

.left-48px {
  left: 48px;
}

.left-n2 {
  left: -0.5rem !important;
}

.right-0 {
  right: 0px;
}

.right-1 {
  right: $spacer * 0.25;
}

.right-2 {
  right: $spacer * 0.5 !important;
}

.right-3 {
  right: $spacer !important;
}

.mt-navbar-height-bottom {
  margin-top: $navbar-height-bottom !important;
}

.mt-n0p5 {
  margin-top: -$spacer * 0.125 !important;
}
.mt-0p5 {
  margin-top: $spacer * 0.125 !important;
}

.ml-0p5 {
  margin-left: $spacer *  0.125 !important;
}

.mr-n0p5 {
  margin-right: -$spacer *  0.125 !important;
}

.pb-0p2 {
  padding-bottom: $spacer * 0.05 !important;
}

.pl-0p2 {
  padding-left: $spacer * 0.05 !important;
}

.flex-1 {
  flex: 1;
}

/* Animation */
.transition-top {
  transition: top 0.2s;
}

/* Visual */
.fake-hidden {
  opacity: 0;
  width: 0px;
  height: 0px;
  resize: none;
  position: fixed;
  right: -10px;
  bottom: -10px;
}

.hide-on-mobile {
  display: none;
}
@include media-breakpoint-up(sm) {
  .hide-on-mobile {
    display: inline-block;
  }
}

.show-on-mobile {
  display: inline-block;
}
@include media-breakpoint-up(sm) {
  .show-on-mobile {
    display: none;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Cursor */
.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer;
}

.link-container {
  @extend .cursor-pointer;
}
.link-container:hover * {
  color: $primary !important;
}

/* Text */
.no-hover-effect {
  text-decoration: none !important;
  color: inherit !important;
}

/**
 * https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
 */
.text-dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.text-display-line-break {
  white-space: pre-line;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

/**
 * https://css-tricks.com/snippets/css/truncate-string-with-ellipsis/
 */
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Button */
.round-button {
  background-color: rgba(0, 0, 0, 0.0);
  border: none;
  width: $round-button-size;
  height: $round-button-size;
  border-radius: 9999px;
  padding: 0px;
}

.round-button-container {
  @extend .round-button;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
/*
 * Effect on touch devices.
 */
.round-button-container:active {
  background: rgba($black, .1);
}
/*
 * Prevent hover effect on touch devices.
 * medium.com/@djpjgj/css-magic-pt-1-prevent-css-hover-on-touch-devices-56b3f8a44240
 */
@media not all and (pointer: coarse) {
  .round-button-container:hover {
    background: rgba($black, .1);
  }
}

button.round-button:focus {
  /* rgba(71, 133, 236 is $primary */
  box-shadow: 0 0 0 0.2rem rgba(71, 133, 236, 0.5);
}

/*
 * Dropdown.
 */
.clean-dropdown>a::before {
  /* Caret off */
  display: none;
}
.clean-dropdown>a::after {
  /* Caret off */
  display: none;
}
.clean-dropdown>.nav-link {
  padding: 0px;
}

.dropdown-menu {
  @extend .border;
  @extend .shadow-sm;
}
.dropdown-menu > .dropdown-item:hover {
    background-color: rgba($black, .1);
    background-image: none;
}

.dropdown-menu-up {
  @extend .right-2;
  @extend .cursor-pointer;
  transform: none !important;
  left: auto !important;
  top: auto !important;
  bottom: 54px !important;
}

/* Image */
.background-image {
  background: no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.thumbnail-image-container {
  @extend .background-image;
  width: 100%;
  padding-top: 56.25%; /* padding in percentages is based on width */
  position: relative;
}

.thumbnail-image-container-75 {
  @extend .background-image;
  width: 100%;
  padding-top: 75%; /* padding in percentages is based on width */
  position: relative;
}

.thumbnail-image-container-100 {
  @extend .background-image;
  width: 100%;
  padding-top: 100%; /* padding in percentages is based on width */
  position: relative;
}

.img-cover {
  object-fit: cover;
}

/* EmailAndPasswordForm */
.form-bottom-link {
  text-decoration: underline;
  text-align: center;
}

/* Tag */
.tag {
  @extend .badge;
  @extend .rounded;
  @extend .py-1;
  @extend .px-2;
  cursor: pointer;
  font-size: 80%;
  font-weight: 600;
}

.tag-primary {
  @extend .tag;
  @extend .badge-primary;
}

.tag-light {
  @extend .tag;
  @extend .badge-light;
  @extend .text-black-50;
}

/*
 * Prevent hover effect on touch devices.
 * medium.com/@djpjgj/css-magic-pt-1-prevent-css-hover-on-touch-devices-56b3f8a44240
 */
@media not all and (pointer: coarse) {
  .tag-primary:hover {
    background: rgba($primary, 2);
  }
  .tag-light:hover {
    background: rgba($black, .1);
  }
}
/*
 * Effect on touch devices.
 */
.tag-primary:active {
    background: rgba($primary, 2);
  }
.tag-light:active {
  background: rgba($black, .1);
}

/* Grid */
@media (min-width: 1400px) {
  .col-xxl-2d4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 1900px) {
  .col-xxxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
}
@media (min-width: 3000px) {
  .col-xxxxl-1d5 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

/* Icon */
.icon-primary {
  fill: $primary;
}

.icon-warning {
  fill: $warning;
}

.icon-dark {
  fill: $dark;
}

.icon-black-50 {
  /* black-50 */
  fill: rgba($black, .5);
}

.icon-white {
  fill: $white;
}

@media (prefers-reduced-motion: no-preference) {
  .spin-icon {
    animation: icon-spin infinite 1.5s linear;
  }
}

@keyframes icon-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Input */
.input-no-clear::-ms-clear {
  display: none;
}

.autosize-input-sizer {
  position: absolute;
  top: 0px;
  left: 0px;
  visibility: hidden;
  height: 0;
  overflow: scroll;
  white-space: pre;
}

/* Special styles on mobile devices */
@include media-breakpoint-down(xs) {
  h1 {
    font-size: 2.2rem;
    font-weight: 400;
  }

  h2 {
    font-size: 1.9rem;
    font-weight: 400;
  }

  h3 {
    font-size: 1.65rem;
    font-weight: 400;
  }

  h4 {
    font-size: 1.4rem;
    font-weight: 400;
  }

  h5 {
    font-size: 1.1rem;
    font-weight: 400;
  }

  h6 {
    font-size: 0.95rem;
    font-weight: 400;
  }

  p {
    font-size: 0.95rem;
    font-weight: 360;
  }

  small {
    font-size: 0.75rem !important;
    font-weight: 340 !important;
  }

  /* Override Bootstrap styles */
  .badge {
    padding: 0.2rem 0.32rem;
  }

  /* Customized styles */

  .mobile-px-gutter {
    /*
     * px-1 on mobile.
     * getbootstrap.com/docs/4.0/utilities/spacing/
     */
    padding-left: $mobile-grid-gutter-width;
    padding-right: $mobile-grid-gutter-width;
  }

  .mobile-h-100px {
    height: 100px;
  }

  .mobile-h-150px {
    height: 150px;
  }

  .mobile-pb-4 {
    padding-bottom: $spacer * 1.5 !important;
  }

  .mobile-mx-n1 {
    margin-left: -$spacer * 0.25 !important;
    margin-right: -$spacer * 0.25 !important;
  }

  .mobile-mt-2 {
    margin-top: $spacer * 0.5 !important;
  }

  .mobile-mt-3 {
    margin-top: $spacer !important;
  }

  .mobile-mb-2 {
    margin-bottom: $spacer * 0.5 !important;
  }

  .mobile-mb-5 {
    margin-bottom: $spacer * 3 !important;
  }

  .mobile-py-2 {
    padding-top: $spacer * 0.5 !important;
    padding-bottom: $spacer * 0.5 !important;
  }

  .mobile-padding-top-100 {
    padding-top: 100%;
  }

  .mobile-block {
    width: 100%;
    display:block;
  }

  .mobile-fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }

  .mobile-line-clamp-1 {
    -webkit-line-clamp: 1;
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
}

/* Special styles on desktop devices */
@include media-breakpoint-up(sm) {
  .desktop-mt-3 {
    margin-top: $spacer !important;
  }

  .desktop-mb-4 {
    margin-bottom: $spacer * 1.5 !important;
  }

  .desktop-my-4 {
    margin-top: $spacer * 1.5 !important;
    margin-bottom: $spacer * 1.5 !important;
  }

  .desktop-py-4 {
    padding-top: $spacer * 1.5 !important;
    padding-bottom: $spacer * 1.5 !important;
  }

  .desktop-line-clamp-2 {
    -webkit-line-clamp: 2;
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .desktop-h-200px {
    height: 200px;
  }
}

/*************/
/* Dark Mode */
/*************/
/* Colors for dark mode */
$darkmode-white: $white;
$darkmode-light: $light;
$darkmode-secondary: $secondary;
$darkmode-dark: $gray-300;
$darkmode-black-50: $gray-500;
$darkmode-btn-light-hover-bg-color: #595959;

/* Default colors for elements */
html, body, #root {
  @extend .bg-white;
}

h1, h2, h3, h4, h5, h6, p, small {
  color: $darkmode-dark !important;
}

hr {
  @extend .border-top;
}

/* Customizations for bootstrap */
.bg-white {
  background-color: $darkmode-white !important;
}

.border {
  border-color: $darkmode-light !important;
}

.border-primary {
  border-color: $primary !important;
}

.border-top {
  border-top-color: $darkmode-light !important;
}

.border-bottom {
  border-bottom-color: $darkmode-light !important;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:disabled,
.btn-light,
.btn-light:hover,
.btn-light:disabled,
.btn-info,
.btn-info:hover,
.btn-info:disabled,
.btn-outline-primary:hover
{
  color: $darkmode-dark;
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active,
.btn-light.active,
.btn-light:active:focus,
.open>.dropdown-toggle.btn-light {
  background-color: $darkmode-btn-light-hover-bg-color !important;
}

.dropdown-menu {
  @extend .bg-light;
  border-color: $darkmode-secondary !important;
}

.dropdown-menu > a:hover {
  color: $darkmode-dark !important;
  background-color: $darkmode-btn-light-hover-bg-color !important;
}

.dropdown-menu > .dropdown-item:hover {
  background-color: $darkmode-btn-light-hover-bg-color !important;
}

.dropdown-item {
  color: $darkmode-dark !important;
}

.dropdown-divider {
  border-top-color: $darkmode-secondary !important;
}

input, .form-control {
  @extend .border;
  background-color: $darkmode-light !important;
  color: $darkmode-dark !important;
}

.text-dark {
  color: $darkmode-dark !important;
}

.text-black-50 {
  color: $darkmode-black-50 !important;
}

.toast-body {
  color: $darkmode-dark !important;
}

.modal-content {
  @extend .bg-white;
  @extend .border;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.tooltip > .arrow::before {
  border-top-color: $darkmode-secondary !important;
  border-bottom-color: $darkmode-secondary !important;
}
.tooltip-inner {
  background-color: $darkmode-secondary !important;
}

.carousel-indicators li {
  background-color: $darkmode-dark;
}

/* Customizations for our own styles */
.icon-black-50 {
  fill: $darkmode-black-50;
}

.icon-dark {
  fill: $darkmode-dark;
}

.bg-grey {
  background-color: $darkmode-btn-light-hover-bg-color !important;
}

.bg-light-hover:hover {
  background-color: $darkmode-btn-light-hover-bg-color !important;
}

.tag-primary {
  color: $darkmode-dark;
}

.round-button-container:active {
  background: $darkmode-btn-light-hover-bg-color;
}
@media not all and (pointer: coarse) {
  .round-button-container:hover {
    background: $darkmode-btn-light-hover-bg-color;
  }
}

@media not all and (pointer: coarse) {
  .tag-light:hover {
    background: $darkmode-btn-light-hover-bg-color;
  }
}
